// socketService.js
import io from 'socket.io-client';

class SocketService {
  socket = null;

  connect(sessionId) {
    if (this.socket && this.socket.connected) {
      console.log('Socket connection already exists and is connected');
      return this.socket;
    }
  const socketUrl = process.env.REACT_APP_SOCKET_URL || 'https://interedu.app'||'https://www.interedu.app';
    //const socketUrl ='http://localhost:3000'; // Backend URL'inizi buraya yazın
    console.log('Connecting to Socket.IO...', socketUrl);

    this.socket = io(socketUrl, {
      query: { sessionId },
      transports: ['websocket', 'polling'],
    });

    this.socket.on('connect', () => {
      console.log('Socket.IO connected successfully! Socket ID:', this.socket.id);
      this.socket.emit('joinSession', sessionId);
    });

    this.socket.on('newQuestion', (newQuestion) => {
      console.log('New question received:', newQuestion);
    });

    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  getSocket() {
    return this.socket;
  }

  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  off(event, callback) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }

  emit(event, data) {
    if (this.socket) {
      console.log(`Emitting ${event}:`, data);
      this.socket.emit(event, data);
    } else {
      console.error('Socket connection does not exist. Emit cannot be performed.');
    }
  }

  // Bu metodu ekleyin veya güncelleyin
  emitNewQuestion(sessionId, question) {
    if (this.socket) {
      console.log('Emitting newQuestion:', question);
      this.socket.emit('newQuestion', { sessionId, question });
    } else {
      console.error('Socket connection does not exist. Cannot emit newQuestion.');
    }
  }
}

export default new SocketService();